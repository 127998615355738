import MobileAppPage from '../../../design-system/page/mobile-app-page/mobile-app-page';
import { AppDataContext } from '../../../design-system/shared/contexts/app-data/AppDataContext';
import {
  AppDataModel,
  GlobalSettings,
  IconPack,
  MobileAppPageContent,
  RegionalLinksModalContent
} from '../../../design-system/shared/models/app-data-model';
import { graphql } from 'gatsby';
import React, { useContext, useEffect } from 'react';

export type ContentStackMobileAppPageProps = {
  data: ContentStackPageQueryResponse;
};

export type ContentStackPageQueryResponse = {
  shMobileAppPages: MobileAppPageContent;
  shSpectrumhealthOrgGlobalSettings: GlobalSettings;
  shRegionalLinksModal?: RegionalLinksModalContent;
};

const ContentStackMobileAppPage: React.FC<ContentStackMobileAppPageProps> = (props: ContentStackMobileAppPageProps) => {
  const context = useContext(AppDataContext);

  useEffect(() => {
    if (context.setAppData) {
      const appData: AppDataModel = {
        ...context.appData
      };

      context.setAppData(appData);
    }
    // we can't depend on context or we endless loop!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MobileAppPage
      content={props.data.shMobileAppPages}
      iconPack={props.data.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[]}
      featureToggles={props.data.shSpectrumhealthOrgGlobalSettings.feature_toggles}
      siteName={props.data.shSpectrumhealthOrgGlobalSettings.title}
      siteUrl={props.data.shSpectrumhealthOrgGlobalSettings.prod_url}
      regionalLinksModalData={props?.data?.shRegionalLinksModal}
    />
  );
};

export const ContentStackMobileAppPageQuery = graphql`
  query ($id: String!) {
    shMobileAppPages(id: { eq: $id }) {
      ...mobileAppPageFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shRegionalLinksModal {
      ...regionalLinksModalFragment
    }
  }
`;

export default ContentStackMobileAppPage;
