/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useRef, useState } from "react";

import EmbeddedScript from "../../shared/components/embedded-script/embedded-script";
import GlobalAlerts from "../../shared/components/global-alerts/global-alerts";
import HeroWrapper from "../../shared/components/hero-wrapper/hero-wrapper";
import SEO from "../../shared/components/seo/seo";
import { AppDataContext } from "../../shared/contexts/app-data/AppDataContext";
import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import useComponentAlerts from "../../shared/hooks/component-alerts/useComponentAlerts";
import usePageHasScrolled from "../../shared/hooks/page-has-scrolled/usePageHasScrolled";
import { MobileAppPageContent, FeatureToggle, IconPack, RegionalLinksModalContent } from "../../shared/models/app-data-model";
import PageModularBlock from "../page-modular-block/page-modular-block";
import "../page.scss";

export type MobileAppPageProps = {
  content: MobileAppPageContent;
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  siteName: string;
  siteUrl: string;
  regionalLinksModalData?: RegionalLinksModalContent;
};

/**
 * This Component renders all mobile app pages (a page type that is different from pages and homepage)
 * We pull in all the mobile-app-page data from graphql and individual page data gets injected as props here
 *
 * @param {MobileAppPageProps} { content }
 * @returns
 */
const MobileAppPage: React.FC<MobileAppPageProps> = ({
  content,
  featureToggles,
  iconPack,
  siteName,
  siteUrl,
  regionalLinksModalData
}: MobileAppPageProps) => {
  const appDataContext = useContext(AppDataContext);
  const uiStateContext = useContext(UiStateContext);
  const showAlerts = uiStateContext.showAlerts;
  const [alertsVisibility, setAlertsVisibility] = useState(true);
  const pageRef = useRef<HTMLDivElement>(null);

  useComponentAlerts(content.alerts || [], "page");

  usePageHasScrolled(pageRef, featureToggles);

  /* istanbul ignore else */
  if (typeof document !== "undefined") {
    document.title = siteName;
  }

  /**
   * This effect sets the value for allow hosts in the UI state context
   * every time a page that uses the mobile-app-page type loads
   */
  useEffect(() => {
    uiStateContext.setAllowHosts?.(content.allowed_domain_list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This effect controls the state of our alerts visibility
   * And that state triggers the animations for the alerts sliding up out of view.
   */
  useEffect(() => {
    setAlertsVisibility(!uiStateContext.pageHasScrolled);
  }, [uiStateContext.pageHasScrolled]);

  /**
   * this hook sets the regional links modal data when we land on a page
   * */
  useEffect(() => {
    appDataContext.setRegionalLinksModalData?.(regionalLinksModalData as RegionalLinksModalContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setRegionalLinksModalData]);

  const seo = (
    <div data-testid={"seo"}>
      <SEO
        siteName={siteName}
        // TODO could switch site url based on active ENV
        siteUrl={siteUrl}
        title={siteName}
        defaultSiteImage={appDataContext?.appData?.primaryHeaderContent?.logo?.url}
        description={siteName}
        pagePath={content.url}
        noIndex={true}
      />
    </div>
  );

  // todo: write unit tests for this
  const embedScripts = appDataContext.embeddedScripts ? (
    <EmbeddedScript embedScriptBlocks={appDataContext.embeddedScripts} featureToggles={featureToggles} pagePath={content.url} />
  ) : null;

  const modularBlocks = content.modular_blocks
    ? content.modular_blocks.map((item, index) => (
        <PageModularBlock
          iconPack={iconPack}
          blockContent={item}
          key={index}
          featureToggles={featureToggles}
          heroType={content?.hero?.[0]?.internal?.type}
        />
      ))
    : null;

  const hero = content?.hero && (
    <HeroWrapper content={content.hero} iconPack={iconPack} subsiteNavPresent={false} featureToggles={featureToggles} />
  );

  return (
    <>
      <div className={`alerts-wrapper ${alertsVisibility && showAlerts ? `expand` : `collapse`}`} id="alert-container">
        <GlobalAlerts iconPack={iconPack} showPageAlertsOnly={true} featureToggles={featureToggles} />
      </div>
      <div>
        {seo}
        {embedScripts}
        <div ref={pageRef} data-testid="page" className="page" id="page-content">
          <div>{hero}</div>
          <div className="modular-block-container">{modularBlocks}</div>
        </div>
      </div>
    </>
  );
};

export default MobileAppPage;
